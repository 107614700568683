import fetch from '../../apis/request'

export default class Apis {
  // 退出登录
  static loginOut() {
    return fetch.post('/pc/user/loginOut')
  }
  // 获得用户信息
  static getUserInfo() {
    return fetch.get('/pc/user/getLoginUserInfo')
  }
  // 修改密码
  static updatePwd(form) {
    return fetch.post(`/pc/user/updatePwd?oldPwd=${form.oldPwd}&newPwd=${form.newPwd}&rePwd=${form.rePwd}`)
  }

}